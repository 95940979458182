<template>
  <div class="tariff"
       :style="{border: border, borderRadius: borderRadius}"
       itemscope>

    <div class="tariff__top"
         :style="{background: headerColor}"
         :class="{ 'tariff__top_accent': tariff.style === 'accent'}">
      <span class="tariff__title" itemprop="name">{{ tariff.name }}</span>
      <UiTariffsTimer v-show="tariff.timer && !priceYear"
                      :color="priceColor"
      :style="{borderRadius: buttonRadius}"/>
    </div>

    <div class="tariff__content__price__title"
         :style="{color: priceColor}">

      <div v-if="(tariff.price.saleRu || tariff.price.saleEn) && !priceYear">
        <div v-if="isDflat">{{ tariff.price.saleRu }}</div>
        <div v-else>{{ tariff.price.saleEn }}</div>
      </div>

      <div :class="{'tariff__content__price__title_sale' : (tariff.price.saleRu || tariff.price.saleEn)}"
           v-if="!priceYear">
        <div v-if="isDflat">{{ tariff.price.ru }}</div>
        <div v-else>{{ tariff.price.en }}</div>
      </div>

      <div v-else>
        <div v-if="isDflat">{{ tariff.price.ruY }}</div>
        <div v-else>{{ tariff.price.enY }}</div>
      </div>

    </div>

    <div class="tariff__content">
      <div class="tariff__content__top">

        <ul class="tariff__content__info">
          <li class="tariff__content__info__title"
              v-for="item in tariff.list.ok">
            <svg style="flex-shrink: 0" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M19.2398 6.43208C19.5535 6.70261 19.5885 7.1762 19.3179 7.48986L10.6929 17.4899C10.5557 17.649 10.3579 17.7433 10.1479 17.7497C9.93783 17.7561 9.73471 17.6741 9.588 17.5236L4.713 12.5236C4.42384 12.227 4.42985 11.7522 4.72643 11.463C5.023 11.1738 5.49784 11.1799 5.787 11.4764L10.0911 15.8909L18.1821 6.51017C18.4526 6.1965 18.9262 6.16154 19.2398 6.43208Z"
                    :style="{ fill: arrowColor}"/>
            </svg>
            <div>{{ $t(item) }}</div>
          </li>
        </ul>

        <ul class="tariff__content__info">
          <li class="tariff__content__info__title"
              v-for="item in tariff.list.accent">
            <svg style="flex-shrink: 0" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.5306 6.53036C18.8235 6.23746 18.8235 5.76258 18.5306 5.46968C18.2377 5.17677 17.7628 5.17677 17.4699 5.46968L12 10.9396L6.53065 5.46994C6.23776 5.17703 5.76289 5.17703 5.46999 5.46994C5.1771 5.76284 5.1771 6.23774 5.46999 6.53065L10.9393 12.0003L5.46967 17.47C5.17678 17.7629 5.17677 18.2378 5.46967 18.5307C5.76257 18.8236 6.23746 18.8236 6.53036 18.5307L12 13.061L17.47 18.5312C17.7629 18.8241 18.2377 18.8241 18.5306 18.5312C18.8235 18.2383 18.8235 17.7634 18.5306 17.4705L13.0607 12.0003L18.5306 6.53036Z"
                    fill="#D93333"/>
            </svg>
            <div>{{ $t(item) }}</div>
          </li>
        </ul>

        <ul class="tariff__content__info">
          <li class="tariff__content__info__title"
              v-for="item in tariff.list.hint">
            <svg style="flex-shrink: 0" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M18.5306 6.53036C18.8235 6.23746 18.8235 5.76258 18.5306 5.46968C18.2377 5.17677 17.7628 5.17677 17.4699 5.46968L12 10.9396L6.53065 5.46994C6.23776 5.17703 5.76289 5.17703 5.46999 5.46994C5.1771 5.76284 5.1771 6.23774 5.46999 6.53065L10.9393 12.0003L5.46967 17.47C5.17678 17.7629 5.17677 18.2378 5.46967 18.5307C5.76257 18.8236 6.23746 18.8236 6.53036 18.5307L12 13.061L17.47 18.5312C17.7629 18.8241 18.2377 18.8241 18.5306 18.5312C18.8235 18.2383 18.8235 17.7634 18.5306 17.4705L13.0607 12.0003L18.5306 6.53036Z"
                    fill="#B2C0CD"/>
            </svg>
            <div class="tariff__text_hint">{{ $t(item) }}</div>
          </li>
        </ul>
      </div>

      <div class="tariff__content__bottom">
        <UiButton
          :label="$t(tariff.button.text)"
          :style="{background: buttonColor, color: buttonTitleColor, border: borderButton, borderRadius: buttonRadius}"
          class="tariff__content__button tariff__content__button_base"
          @click="redirect"
        />
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import {useBaseStore} from '~/modules/BaseModule/base.store';

const isShowFeedBackModal = ref(false);
const runtimeConfig = useRuntimeConfig();
const isDflat = runtimeConfig.public.vueAppBaseUrl.includes('d-flat.ru');
const base = useBaseStore();

const redirect = () => {
  if (props.tariff.button.download) {
    base.downloadInstaller();
  } else if (props.priceYear)
    navigateTo(props.tariff.button.pathY);
  else
    navigateTo(props.tariff.button.path);
}

const props = withDefaults(
  defineProps<{
    tariff: Object,
    borderRadius: String,
    border: String,
    borderButton?: String,
    headerColor?: String,
    buttonRadius?: String,
    priceColor?: String,
    arrowColor?: String,
    priceYear?: Boolean,
    buttonColor: String,
    buttonTitleColor: String,
  }>(),
  {
    tariff: {
      id: 0,
      name: 'FREE',
      timer: false,
      style: 'base',
      price: {
        ru: '100 р',
        en: '$0',
        ruY: '1200 р',
        enY: '$0000',
      },
      list: {
        ok: [
          'tariffs_allFunctions',
          'tariffs_savePlans3',
        ],
        accent: ['designSaveUnavailable'],
        hint: [
          'watermarksRemoval',
          'prioritySup',
          'tariffs_education'
        ],
      },
      button: {
        text: 'Подписатсья',
        download: false,
        path: '/',
        pathY: '/',
      },
    },
  },
);
</script>

<style lang="scss" scoped>
a {
  color: var(--white-theme-blue-100, #2491e7);
}

.tariff {
  box-sizing: border-box;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  width: 100%;

  border-radius: 4px;

  @media (max-width: 720px) {
    max-width: initial;
  }
}

.tariff__top {
  height: 64px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #0081FF;

  padding: 16px 24px;
}

.tariff__title {
  //styleName: Headline/H3;
  font-family: Segoe UI;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.92px;
  letter-spacing: 0.03em;
  text-align: left;
  color: white;
}

.tariff__content {
  height: 100%;

  box-sizing: border-box;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tariff__content__info {
  list-style: none;
  padding: 0;
}

.tariff__content__info__title {
  display: flex;
  align-items: center;
  gap: 12px;

  margin-top: 16px;

  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
}

.tariff__content__info__subtitle {
  //styleName: Launcher/P3 (body);
  font-family: Segoe UI;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: var(--var-gray-200);
}

.tariff__content__price {
  margin: 24px 0;
}

.tariff__content__price__title {
  //styleName: Headline/H1;

  display: flex;

  margin: 24px 24px 0 24px;

  font-family: Segoe UI;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  letter-spacing: 0.03em;
  text-align: left;
}

.tariff__content__price__title_sale {
  color: #B2C0CD;
  text-decoration: line-through;
  font-size: 18px;
  line-height: 28px;
  align-self: flex-end;

  padding-left: 10px;
}

.tariff__content__price__subtitle {
  color: var(--var-gray-200);
}


.tariff__content__button {
  margin-top: 40px;
  width: 100%;
  border-radius: 4px;
  color: var(--var-white-000);
}

.tariff__content__button_base {
  box-shadow: 0px 0px 12px 0px rgba(10, 10, 10, 0.15);
}

.tariff__text_hint {
  color: #B2C0CD;
}

.tariff__top_accent {
  background: linear-gradient(90deg, #0081FF 0%, #004D99 100%);
}
</style>

