<template>
  <div class="timer">
    <span :style="{ borderRadius: borderRadius }" class="timer__time">
      {{ formattedHours }}:{{ formattedMinutes }}:{{ formattedSeconds }}
    </span>
    <span :style="{ color: color }" class="timer__sale">-33%</span>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  color: String,
  borderRadius: String
});

// Функция для форматирования чисел с ведущими нулями
function formatNumber(number) {
  return number.toString().padStart(2, '0');
}

// Создаем реактивные переменные
const hours = ref(24);
const minutes = ref(0);
const seconds = ref(0);

// Загружаем время из localStorage или устанавливаем значение по умолчанию
function loadTime() {
  if (process.client) {
    const savedTime = JSON.parse(localStorage.getItem('timer'));
    if (savedTime) {
      hours.value = savedTime.hours;
      minutes.value = savedTime.minutes;
      seconds.value = savedTime.seconds;
    } else {
      hours.value = 24;
      minutes.value = 0;
      seconds.value = 0;
    }
  }
}

// Сохраняем время в localStorage
function saveTime() {
  if (process.client) {
    const time = {
      hours: hours.value,
      minutes: minutes.value,
      seconds: seconds.value
    };
    localStorage.setItem('timer', JSON.stringify(time));
  }
}

// Функция для уменьшения времени на одну секунду
function decrementTime() {
  if (seconds.value > 0) {
    seconds.value--;
  } else if (minutes.value > 0) {
    seconds.value = 59;
    minutes.value--;
  } else if (hours.value > 0) {
    seconds.value = 59;
    minutes.value = 59;
    hours.value--;
  } else {
    clearInterval(timerInterval); // Останавливаем таймер, если время закончилось
  }
  saveTime(); // Сохраняем время после каждого изменения
}

// Запускаем таймер при монтировании компонента
let timerInterval;

onMounted(() => {
  loadTime(); // Загружаем время при монтировании
  timerInterval = setInterval(decrementTime, 1000);
});

// Останавливаем таймер при размонтировании компонента
onUnmounted(() => {
  clearInterval(timerInterval);
});

// Форматируем часы, минуты и секунды для отображения
const formattedHours = computed(() => formatNumber(hours.value));
const formattedMinutes = computed(() => formatNumber(minutes.value));
const formattedSeconds = computed(() => formatNumber(seconds.value));
</script>

<style scoped>
.timer {
  box-sizing: border-box;
  padding: 6px 12px;

  display: flex;
  align-items: center;
  gap: 8px;

  background: white;
  border-radius: 2px;
}

.timer__time {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #839AAF;
}

.timer__sale {
  font-family: Segoe UI;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.28px;
  letter-spacing: 0.02em;
  text-align: center;
}
</style>
