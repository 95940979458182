<template>
  <div class="tariff-list">
    <slot>

    </slot>
  </div>
</template>

<script setup>


</script>

<style scoped lang="scss">
.tariff-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}
</style>
